// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.importar-nomina-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="file"],
button[type="submit"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button[type="submit"] {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.button-group {
  margin-top: 20px;
  text-align: center;
}

.formato-archivo {
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

.formato-archivo h3 {
  margin-bottom: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/ImportarNomina.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;;;EAGE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;EAC1B,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".importar-nomina-container {\n  max-width: 600px;\n  margin: 0 auto;\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n}\n\n.form-group {\n  margin-bottom: 10px;\n}\n\n.form-group label {\n  display: block;\n  margin-bottom: 5px;\n}\n\ninput[type=\"text\"],\ninput[type=\"file\"],\nbutton[type=\"submit\"] {\n  width: 100%;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n}\n\nbutton[type=\"submit\"] {\n  background-color: #007bff;\n  color: white;\n  cursor: pointer;\n}\n\n.button-group {\n  margin-top: 20px;\n  text-align: center;\n}\n\n.formato-archivo {\n  margin-top: 20px;\n  border-top: 1px solid #ccc;\n  padding-top: 20px;\n}\n\n.formato-archivo h3 {\n  margin-bottom: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
