import React from 'react';
import ImportarNomina from './ImportarNomina';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Importar Nómina</h1>
        <ImportarNomina />
      </header>
    </div>
  );
}

export default App;

