import React from 'react';
import './ImportarNomina.css';

class ImportarNomina extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      folio_negocio: '',
      nro_grupo: '',
      nro_nom: '',
      archivo: null
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { folio_negocio, nro_grupo, nro_nom, archivo } = this.state;
    if (!folio_negocio || !nro_grupo || !nro_nom || !archivo) {
      alert("Por favor complete todos los campos y cargue un archivo.");
      return;
    }
    const url = `http://aguila.bicevida.cl/intranet%20corporativa/COLECTIVOS/Ventas%20Colectivos/Cotizador_Colectivo/CotColectivos/UploadSend.asp?folio_negocio=${folio_negocio}&nro_grupo=${nro_grupo}&nro_nom=${nro_nom}`;
    window.open(url, '_blank');
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleFileChange = (event) => {
    this.setState({ archivo: event.target.files[0] });
  }

  render() {
    return (
      <div className="importar-nomina-container">
        <h2>Importar Nómina</h2>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label>Folio Negocio:</label>
            <input type="text" name="folio_negocio" value={this.state.folio_negocio} onChange={this.handleChange} />
          </div>
          <div className="form-group">
            <label>Número de Grupo:</label>
            <input type="text" name="nro_grupo" value={this.state.nro_grupo} onChange={this.handleChange} />
          </div>
          <div className="form-group">
            <label>Número de Nominación:</label>
            <input type="text" name="nro_nom" value={this.state.nro_nom} onChange={this.handleChange} />
          </div>
          <div className="form-group">
            <label>Cargar Archivo:</label>
            <input type="file" onChange={this.handleFileChange} />
          </div>
          <button type="submit">Continuar</button>
        </form>
        <div className="formato-archivo">
          <h3>Recomendaciones de Formato del Archivo</h3>
          <p>- Nombre archivo máximo 12 caracteres, sin espacios en blanco</p>
          <p>- Extensión .xlsx</p>
          <p>- Debe contener al menos: fecha de nacimiento de cada asegurado</p>
        </div>
      </div>
    );
  }
}

export default ImportarNomina;

